import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Video from "../../components/video"

const Page = () => {
  const metadata = {
    title: "Grayson Colt Holmes",
    heroH1: "Grayson Colt Holmes",
    heroImage: "photo-bg-staff.jpg",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Grayson Colt Holmes</h2>
            <div className="w-full md:w-1/2 mb-8 float-right">
              <Video videoSrcURL="https://www.youtube.com/embed/_4GRA9L_ycU?rel=0" />
            </div>
            <div>
              <p className="mb-8">
                Grayson, who is known as “Colt”, is an associate with the firm. He
                focuses primarily on DUI and criminal defense, personal injury
                cases, and civil defense litigation.
              </p>
              <p className="mb-8">
                Prior to joining the firm, Colt was a prosecutor at the Chief
                State’s Attorney’s office in Rocky Hill. There he prosecuted
                criminal and DUI cases in various court houses across the state
                and defended the Commissioner of Corrections in habeas actions.
              </p>
              <p className="mb-8">
                Before his work as a prosecutor, Colt’s primary practice was DUI
                and criminal defense. He has tried several cases and has
                successfully defended hundreds of criminal defendants.
              </p>
              <p className="mb-8">
                Colt is a graduate for the University of Georgia, where he received
                a bachelor’s degree in Political Science and English. He received
                his Juris Doctorate from the University of Connecticut Law School.
                He is published as the author of “The New Employment Verification
                Act: the Functionality & Constitutionality of Biometrics in the
                Hiring Process,” 43 Conn. Law. 673 (2010) and has also served as a
                clerk to The Honorable Michael Sheldon.
              </p>
            </div>

            <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
              <figure style={{ height: "150px", width: "150px" }}>
                <StaticImage
                  className="rounded-l-2xl"
                  height={"150"}
                  quality="100"
                  src="../../images/atty_holmes.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt="Photo of Grayson Colt Holmes"
                  imgStyle={{
                    borderTopLeftRadius: "1rem",
                    borderBottomLeftRadius: "1rem",
                  }}
                />
              </figure>
              <div class="px-4 pb-2 text-gray-900">
                <p className="text-xl font-bold">Grayson Colt Holmes</p>
                <p className="">Partner</p>
                <p className="">
                  <a href="tel:+1-203-272-1157">203-272-1157</a>  <strong>phone</strong>
                </p>
                <p className="">
                  203-250-1835 <strong>fax</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
